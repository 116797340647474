/* 容器样式保持不变 */
.container {
    padding: 16px;
}

/* 移除未使用的 .list-item 样式，因为这里使用的是 Card 组件 */
.buttons {
    margin-top: 10px;
}

/* 按钮样式调整 */
.button {
    flex: 1; /* 如果需要可以保留，但通常按钮宽度固定或自适应内容更好 */
    margin-right: 8px;
    padding: 6px 12px; /* 调整内边距 */
    border-radius: 4px; /* 圆角 */
}

.button:last-child {
    margin-right: 0; /* 最后一个按钮无右边距 */
}

.button:hover {
    background-color: #0056b3; /* 鼠标悬停时颜色变深 */
}

/* 卡片样式调整 */
.card-item {
    margin-bottom: 20px;
    border: 1px solid #ddd; /* 添加边框 */
    border-radius: 4px; /* 圆角 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 添加阴影增加立体感 */
    transition: box-shadow 0.3s ease; /* 阴影变化过渡效果 */
}

.card-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 鼠标悬停时阴影加深 */
}

/* 分页样式保持不变，但可以进一步调整颜色或间距 */
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.pagination span {
    margin: 0 10px;
    color: #333; /* 确保文本颜色清晰可读 */
}

/* Ant Design Mobile 的按钮样式，确保与自定义按钮一致（如果使用了 am-button 类）*/
.pagination .am-button {
    background-color: #007bff; /* 与自定义按钮一致 */
    border-color: #007bff;
    color: white;
    padding: 6px 15px;
    border-radius: 4px;
}

.pagination .am-button:disabled {
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
}

.pagination .am-button:not(:disabled):hover {
    background-color: #0056b3; /* 鼠标悬停时颜色变深，与自定义按钮一致 */
}

.checkbox-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.checkbox-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-container {
    margin-bottom: 10px;
}

.button-container .button-danger {
    background-color: #dc3545;
    color: white;
    border: none;
}

.button-container .button:disabled {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}

.no-data-message {
    text-align: center;
    color: #999;
    font-size: 16px;
    margin-top: 20px;
}
