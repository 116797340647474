.zxyy-page{
  .reservation{
    padding: 0 10px;
    position: relative;
    .loading{
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255,255,255,0.7);
      z-index: 999;
      font-size: 16px;
      color: #1677ff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .block-title{
      color: #0F1D27;
      font-weight: bolder;
      font-size: 18px;
      background-color: #D7E5FF;
      height: 50px;
      line-height: 50px;
      text-indent: 14px;
      position: relative;
      &::before{
        position: absolute;
        display: block;
        content: '';
        width: 4px;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #327BF5;
      }
    }
    .picker-item{
      .icon{
        color: #cccccc;
      }
    }
    .adm-input-element{
      text-align: right;
    }
    .adm-list-item-content-main{
      text-align: right;
    }
    .xzq-info{
      padding: 20px 10px;
      border-radius: 8px;
      border: 1px solid #1674FA;
      font-weight: bolder;
      .department-name{
        font-size: 18px;
      }
      .department-phone,.department-address{
        margin-top: 10px;
        display: flex;
        align-items: center;
        color: #1674FA;
        .icon{
          margin-right: 20px;
          font-size: 24px;
        }
      }
    }
  }
  .history-list{
    .history-item{
      padding: 12px;
      margin-top: 10px;
      border-radius: 8px;
      box-shadow: 0 3px 5px 0 rgba(0,0,0,0.2);
      font-weight: bolder;
    }
    .history-item>div{
      display: flex;
    }
  }
  .empty{
    text-align: center;
    height: 50px;
  }
}
