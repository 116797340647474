.gsgg-page {
  .page-content {
    position: relative;
    padding: 10px;

    .loading {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 999;
      font-size: 16px;
      color: #1677ff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .search-bar {
      margin-bottom: 20px;
    }

    .notice-list {
      .notice-item {
        height: 60px;
        border-radius: 6px;
        box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.4);
        margin-bottom: 14px;
        padding: 10px;
        position: relative;

        .notice-title {
          font-size: 16px;
          font-weight: bolder;
          display: -webkit-box; /* 将元素作为弹性伸缩盒子 */
          overflow: hidden; /* 隐藏溢出部分 */
          text-overflow: ellipsis; /* 使用省略号表示被裁切的文本 */
          -webkit-line-clamp: 2; /* 只显示前两行 */
          -webkit-box-orient: vertical; /* 垂直布局 */
        }

        .notice-tips {
          font-size: 12px;
          position: absolute;
          bottom: 10px;

          span {
            margin-right: 16px;
          }
        }
      }
    }
    .pagination{
      margin-top: 30px;
      text-align: center;
    }

    .empty {
      text-align: center;
    }
  }

  .detail-modal {
    .adm-center-popup-wrap {
      width: 100vw;
      height: 100vh;
      max-width: 100vw;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: none;

      .adm-modal-body {
        height: 100vh;
        max-height: 100vh;
        .adm-modal-content{
          max-height: 73vh;
        }
        p{
          //为了让图片可以用滚动的方式全部显示
          overflow: scroll;
        }
      }
    }
  }
}
