.jdts_page .tsBtn {
  position: fixed;
  bottom: 5%;
  right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--adm-color-warning);
  color: #ffffff;
}
.jdts_page .jdts_list_prefix {
  padding: 5px 10px;
  background-color: #327BF5;
  color: #ffffff;
  border-radius: 4px;
  font-size: 15px;
}
.jdts_page .jdts_list_prefix .jdts_list_prefix_date {
  color: #ffffff80;
}
.jdts_page .jdts_list_item {
  min-height: 51px;
}
.jdts_page .search-header {
  padding: 12px;
  background: var(--adm-color-background);
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 999999;
  top: 0;
  left: 0;
}
.jdts_page .search-header .search-left {
  flex: auto;
}
.jdts_page .search-header .search-right {
  flex: none;
  padding-left: 12px;
}
.jdts-loading {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
}
.detail-box {
  padding: 20px 10px;
  font-size: var(--adm-font-size-7);
}
.detail-box .detail-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.detail-box .detail-time {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}
.detail-box .detail-content {
  padding: 10px 0;
}
.detail-box.boxFk {
  background-color: rgba(167, 223, 255, 0.1);
}
.detail-box.boxHf {
  margin-top: 10px;
  background-color: rgba(255, 246, 156, 0.1);
}
