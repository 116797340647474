.gsgg-page .page-content {
    position: relative;
    padding: 10px;
}

.gsgg-page .page-content .loading {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 999;
    font-size: 16px;
    color: #1677ff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gsgg-page .page-content .search-bar {
    margin-bottom: 20px;
}

.gsgg-page .page-content .notice-list .notice-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 14px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(166, 170, 176, .12);
    margin: 19px auto;
    letter-spacing: 1px;
}

.gsgg-page .page-content .notice-list .notice-item .notice-item-icon {
    width: 10%;
}

.gsgg-page .page-content .notice-list .notice-item .notice-item-content {
    width: 90%;
}

.gsgg-page .page-content .notice-list .notice-item .notice-title {
    font-size: 16px;
    display: -webkit-box;
    /* 将元素作为弹性伸缩盒子 */
    overflow: hidden;
    /* 隐藏溢出部分 */
    text-overflow: ellipsis;
    /* 使用省略号表示被裁切的文本 */
    -webkit-line-clamp: 2;
    /* 只显示前两行 */
    -webkit-box-orient: vertical;
    min-height: 30px;
}

.gsgg-page .page-content .notice-list .notice-item .notice-tips {
    padding-top: 9px;
    color: #999;
    font-size: 13px;
    display: flex;
    display: -webkit-flex;
    display: -o-flex;
    display: -ms-flex;
    display: -moz-flex;
    justify-content: space-around;
}

.gsgg-page .page-content .notice-list .notice-item .notice-tips span:nth-child(1) {
    width: 60%;
}

.gsgg-page .page-content .notice-list .notice-item .notice-tips span:nth-child(2) {
    width: 40%;
    display: -webkit-box;
    /* 将元素作为弹性伸缩盒子 */
    overflow: hidden;
    /* 隐藏溢出部分 */
    text-overflow: ellipsis;
    /* 使用省略号表示被裁切的文本 */
    -webkit-line-clamp: 1;
    /* 只显示前两行 */
    -webkit-box-orient: vertical;
}

.gsgg-page .page-content .pagination {
    margin-top: 30px;
    text-align: center;
}

.gsgg-page .page-content .empty {
    text-align: center;
}

.gsgg-page .detail-modal .adm-center-popup-wrap {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
}

.gsgg-page .detail-modal .adm-center-popup-wrap .adm-modal-body {
    height: 100vh;
    max-height: 100vh;
}

.gsgg-page .detail-modal .adm-center-popup-wrap .adm-modal-body .adm-modal-content {
    max-height: 82vh;
}

.gsgg-page .detail-modal .adm-center-popup-wrap .LinkModal {
    padding-top: 10px;
}

.gsgg-page .detail-modal .adm-center-popup-wrap .LinkModal .adm-modal-content {
    max-height: 86vh;
}


.gsgg-page .detail-modal .adm-center-popup-wrap .adm-modal-body p {
    overflow: scroll;
}

