.zcfg-page .page-content {
  position: relative;
  padding: 10px;
}
.zcfg-page .page-content .loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
  font-size: 16px;
  color: #1677ff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.zcfg-page .page-content .search-bar {
  margin-bottom: 20px;
}
.zcfg-page .page-content .policy-list .policy-item {
  height: 60px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.4);
  margin-bottom: 14px;
  padding: 10px;
  position: relative;
}
.zcfg-page .page-content .policy-list .policy-item .policy-title {
  font-size: 16px;
  font-weight: bolder;
  display: -webkit-box;
  /* 将元素作为弹性伸缩盒子 */
  overflow: hidden;
  /* 隐藏溢出部分 */
  text-overflow: ellipsis;
  /* 使用省略号表示被裁切的文本 */
  -webkit-line-clamp: 2;
  /* 只显示前两行 */
  -webkit-box-orient: vertical;
  /* 垂直布局 */
}
.zcfg-page .page-content .policy-list .policy-item .policy-tips {
  font-size: 12px;
  position: absolute;
  bottom: 10px;
}
.zcfg-page .page-content .policy-list .policy-item .policy-tips span {
  margin-right: 16px;
}
.zcfg-page .page-content .pagination {
  margin-top: 30px;
  text-align: center;
}
.zcfg-page .page-content .empty {
  text-align: center;
}
.zcfg-page .detail-modal .adm-center-popup-wrap {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: none;
}
.zcfg-page .detail-modal .adm-center-popup-wrap .adm-modal-body {
  height: 100vh;
  max-height: 100vh;
}
.zcfg-page .detail-modal .adm-center-popup-wrap .adm-modal-body .adm-modal-content {
  max-height: 73vh;
}
.zcfg-page .detail-modal .adm-center-popup-wrap .adm-modal-body p {
  overflow: scroll;
}
