.fwpj-page .container {
  padding: 0 10px;
  position: relative;
}
.fwpj-page .container .loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
  font-size: 16px;
  color: #1677ff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fwpj-page .container .fwpj-form .pjly-title {
  font-size: 12px;
  padding: 5px 10px 5px 20px;
}
